import * as React from 'react';
import {
  Box,
  FormControlLabel,
  Switch,
  Tooltip
} from "@mui/material";
import {styled} from '@mui/material/styles';

const SwitchControl = styled(Switch)(({theme}) => ({

  padding: 8,
  '& .MuiSwitch-switchBase': {
    transitionDuration: '300ms',
    '&.Mui-checked': {
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: '#39da8a',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#39da8a',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: theme.palette.grey[200],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: 0.2,
    },
  },
  '& .MuiSwitch-track': {
    borderRadius: 22 / 2,
    '&::before, &::after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      width: 16,
      height: 16,
    },
    '&::before': {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main),
      )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
      left: 12,
    },
    '&::after': {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main),
      )}" d="M10.9393 12L6.9696 15.9697L8.03026 17.0304L12 13.0607L15.9697 17.0304L17.0304 15.9697L13.0607 12L17.0303 8.03039L15.9696 6.96973L12 10.9393L8.03038 6.96973L6.96972 8.03039L10.9393 12Z" /></svg>')`,
      right: 12,
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: 'none',
    width: 16,
    height: 16,
    margin: 2,
  },
}));

const ToggleSwitch = (props) => {

  const onSwitchToggle = (item) => {
    props.onToggle(item);
  };

  let item = props.data

  return (
    <FormControlLabel
      control={
        props?.hover ? (
          <Tooltip
            componentsProps={{
              tooltip: {
                sx: {
                  marginTop: "0px !important",
                  marginBottom: "0px !important",
                  backgroundColor: "#EEE",
                  color: "#000",
                },
              },
            }}
            title={props?.hoverText}
            placement="top"
          >
            <Box>
              <SwitchControl
                disabled={props?.disabled ?? false}
                onChange={() => onSwitchToggle(item)}
                checked={props.checkedVal == 1 ? true : false}/>
            </Box>
          </Tooltip>
        ) : (
          <SwitchControl
            disabled={props?.disabled ?? false}
            onChange={() => onSwitchToggle(item)}
            checked={props.checkedVal == 1 ? true : false}/>
        )
      }
    />
  );
};

export default ToggleSwitch;
