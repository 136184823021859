import {put, takeLatest, call} from "redux-saga/effects";

import {
  LOGIN_ADMIN,
  ADD_USER,
  LIST_USER,
  GET_USER_DETAIL,
  UPDATE_USER,
  UPDATE_USER_DATA,
  DELETE_USER,
  ADD_UPDATE_CRON_DURATION,
  LIST_CRON_DURATION,
  LIST_LOG
} from "../actions/types";
import {
  loginAdminSuccess,
  loginAdminFail,
  addUserSuccess,
  addUserFail,
  listUserSuccess,
  listUserFail,
  getUserDetailSuccess,
  getUserDetailFail,
  updateUserSuccess,
  updateUserFail,
  updateUserDataSuccess,
  updateUserDataFail,
  deleteUserSuccess,
  deleteUserFail,
  addUpdateCronDurationSuccess,
  addUpdateCronDurationFail,
  listCronDurationSuccess,
  listCronDurationFail,
  listLogSuccess,
  listLogFail,
} from "../actions/userDataActions";

import * as Api from "../../api";

function* onLoginAdmin(data) {
  try {
    const response = yield call(Api.adminLogin, data.payload);
    if (response.status == "Success") {
      yield put(loginAdminSuccess(response));
    } else {
      yield put(loginAdminFail(response));
    }
  } catch (error) {
    yield put(loginAdminFail({msg: "Failed"}));
  }
}

function* onAddUser(data) {
  try {
    const response = yield call(Api.addUser, data.payload);
    if (response.status == "Success") {
      window.location.href = "/users";
      yield put(addUserSuccess(response));
    } else {
      yield put(addUserFail(response));
    }
  } catch (error) {
    yield put(addUserFail({msg: "Failed"}));
  }
}

function* onListUser(data) {
  try {
    const response = yield call(Api.listUser, data.payload);
    if (response.status == "Success") {
      yield put(listUserSuccess(response));
    } else {
      yield put(listUserFail(response));
    }
  } catch (error) {
    yield put(listUserFail("Failed"));
  }
}

function* onGetUserDetail(data) {
  try {
    const response = yield call(Api.getUserDetail, data.payload);
    if (response.status == "Success") {
      yield put(getUserDetailSuccess(response));
    } else {
      window.location.href = "/users";
      yield put(getUserDetailFail(response));
    }
  } catch (error) {
    window.location.href = "/users";
    yield put(getUserDetailFail("Failed"));
  }
}

function* onUpdateUser(data) {
  try {
    const response = yield call(Api.updateUser, data.payload);
    if (response.status == "Success") {
      yield put(updateUserSuccess(response));
      window.location.href = "/users";
    } else {
      yield put(updateUserFail(response));
    }
  } catch (error) {
    yield put(updateUserFail({msg: "Failed"}));
  }
}

function* onUpdateUserData(data) {
  try {
    const response = yield call(Api.updateUserData, data.payload);
    if (response.status == "Success") {
      yield put(updateUserDataSuccess(response));
    } else {
      yield put(updateUserDataFail(response));
    }
  } catch (error) {
    yield put(updateUserDataFail({msg: "Failed"}));
  }
}

function* onDeleteUser(data) {
  try {
    const response = yield call(Api.deleteUser, data.id);
    if (response.status == "Success") {
      yield put(deleteUserSuccess({ ...response, id: data.id }));
    } else {
      yield put(deleteUserFail(response));
    }
  } catch (error) {
    yield put(deleteUserFail({msg: "Failed"}));
  }
}

function* onAddUpdateCronDuration(data) {
  try {
    const response = yield call(Api.addUpdateCronDuration, data.payload);
    let payload_data = JSON.parse(data.payload);
    if (response.status == "Success") {
      yield put(addUpdateCronDurationSuccess({...response, data: payload_data}));
    } else {
      yield put(addUpdateCronDurationFail(response));
    }
  } catch (error) {
    yield put(addUpdateCronDurationFail({msg: "Failed"}));
  }
}

function* onListCronDuration() {
  try {
    const response = yield call(Api.listCronDuration);
    if (response.status == "Success") {
      yield put(listCronDurationSuccess(response));
    } else {
      yield put(listCronDurationFail(response));
    }
  } catch (error) {
    yield put(listCronDurationFail("Failed"));
  }
}

function* onListLog(data) {
  try {
    const response = yield call(Api.listLog, data.payload);
    if (response.status == "Success") {
      yield put(listLogSuccess(response));
    } else {
      yield put(listLogFail(response));
    }
  } catch (error) {
    yield put(listLogFail("Failed"));
  }
}

function* userDataSaga() {
  yield takeLatest(LOGIN_ADMIN, onLoginAdmin);
  yield takeLatest(ADD_USER, onAddUser);
  yield takeLatest(LIST_USER, onListUser);
  yield takeLatest(GET_USER_DETAIL, onGetUserDetail);
  yield takeLatest(UPDATE_USER, onUpdateUser);
  yield takeLatest(UPDATE_USER_DATA, onUpdateUserData);
  yield takeLatest(DELETE_USER, onDeleteUser);
  yield takeLatest(ADD_UPDATE_CRON_DURATION, onAddUpdateCronDuration);
  yield takeLatest(LIST_CRON_DURATION, onListCronDuration);
  yield takeLatest(LIST_LOG, onListLog);
}

export default userDataSaga;
