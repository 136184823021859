import * as React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import { THEME_COLOR3 } from "../constants";

const Footer = () => {
  return (
    <Grid container direction="row" justifyContent="space-between">
      <Typography
        sx={{
          color: THEME_COLOR3,
          fontSize: 10,
          fontFamily: "IBMPlexSansRegular",
        }}
      >
        Copyright © 2024 Us Visa Bot. All rights reserved.
      </Typography>
    </Grid>
  );
};

export default Footer;
