import React,{useCallback} from 'react';
import {Box, Typography, TextField, Grid} from "@mui/material";

import styles from "./FormTextInput.module.css"

const FormTextInput = (props) => {

  const onIconClick = () => {
    props.onIconClick();
  };

  return (
    <Grid item md={12} xs={12}
          container
          display={"flex"}>
      {props.label ?
        <Grid display={"flex"} flexDirection={"row"} item md={12} xs={12} container>
          <Typography className={styles.labelText}>{props.label}</Typography>
          {props?.required ?
            <Box>
              <Typography className={styles.requiredText} mt={-0.3} pl={0.3}>*</Typography>
            </Box>
            : null}
        </Grid>
        : null}
      <Grid
        sx={style.textInputView}
        className={[props.error ? styles.errorBorderStyle : styles.borderStyle]}
        item md={12} xs={12}
        container>
        <TextField
          inputRef={props?.inputRef}
          disabled={props?.disabled ? props?.disabled : false}
          placeholder={props?.placeholder}
          required={props?.required ? true : false}
          fullWidth
          variant={props?.variant ? props?.variant : "standard"}
          value={props.value}
          type={props?.type ? props.type : 'text'}
          onChange={e => props.setValue(e.target.value)}
          sx={{
            '& .MuiInput-root': {
              '&:before, :after, :hover:not(.Mui-disabled):before': {
                borderBottom: 0,
              },
              transition: "0.2s",
              paddingRight: 2,
            },
            "& .Mui-focused": {
              border: props?.error ? 'unset !important' : '1px solid #5a8dee !important',
              height: props?.height ? props.height : 36,
              borderRadius: 1,
              transition: "0.2s",
              paddingLeft: 2,
            },
            input: {
              "&::placeholder": {
                fontFamily: 'IBMPlexSansRegular',
                color: '#BBB',
                fontSize: 14,
                opacity: 1,
              },
            },
          }}
          InputProps={{
            autoComplete: 'none',
            sx: {...style.textInputValue, px: 1.5},
            endAdornment:
              <Box
                className={[styles.iconView,
                  props?.icon ? styles.enableIconView : styles.disableIconView]}
                onClick={() => onIconClick()}>
                {props?.rightIcon ?
                  (props?.rightIcon) :
                  null}
              </Box>
          }}
        />
      </Grid>
      {props.error ?
        <Box mt={0.7} className={styles.errorView}>
          <Typography className={styles.errorText}>{props.error}</Typography>
        </Box>
        : null}
    </Grid>
  )
}

export default FormTextInput;

const style = {
  textInputView: {
    backgroundColor: '#FFF',
    display: 'flex',
    alignItems: 'center',
    border: '1px solid #CCC',
    height: 36,
    borderRadius: 1,
  },
  textInputValue: {
    color: '#000',
    fontFamily: 'IBMPlexSansRegular',
    fontSize: 14,
  },
};
