import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";

import { connect } from "react-redux";

import Login from "./containers/Auth/Login";
import LogOut from "./containers/Auth/LogOut";
import Users from "./containers/Users/Users";
import AddUser from "./containers/Users/AddUser";
import Settings from "./containers/Settings/Settings";
import Logs from "./containers/Logs/Logs";

import "./assets/css/custom.css";

const App = (props) => {
  let routes = (
    <Routes>
      <Route exact path="/login" element={<Login {...props} />} />
      <Route exact path="/" element={<Navigate replace to="/login" />} />
      <Route path="*" element={<Navigate replace to="/login" />} />
    </Routes>
  );

  if (props.userToken) {
    routes = (
      <Routes>
        <Route exact path="/index" element={<Navigate replace to="/"/>}/>
        <Route exact path="/" element={<Users {...props} />}/>
        <Route exact path="/add_edit_user" element={<AddUser {...props} />}/>
        <Route exact path="/add_edit_user/:user_id" element={<AddUser {...props} />}/>
        <Route exact path="/settings" element={<Settings {...props} />}/>
        <Route exact path="/logs" element={<Logs {...props} />}/>
        <Route exact path="/logout" element={<LogOut {...props} />} />
        <Route path="/" element={<Navigate replace to="/" />} />
        <Route path="*" element={<Navigate replace to="/" />} />
      </Routes>
    );
  }

  return <BrowserRouter>{routes}</BrowserRouter>;
};

const mapStateToProps = (state) => {
  return {
    userToken: state.userData.userToken,
  };
};

export default connect(mapStateToProps, null)(App);
