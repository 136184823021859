import {AUTHENTICATION_FAILED, ENV} from '../constants';
import axios from 'axios';

import {store} from '../redux/store';
import {logOut} from "../redux/actions/userDataActions";

let apiUrl = '';

if (ENV === 'PROD') {
  apiUrl = 'https://visabotapi.trizova.com/admin/';
} else {
  apiUrl = 'http://localhost:5432/admin/';
}

function userToken() {
  const state = store.getState();
  let token = state.userData.userToken;
  return token;
}

export async function adminLogin(data) {
  let config = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('login', config);
}

export async function addUser(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('user/add', config);
}

export async function listUser(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('user/list', config);
}

export async function getUserDetail(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('user/get', config);
}

export async function updateUser(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('user/update', config);
}

export async function updateUserData(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('user/update_data', config);
}


export async function deleteUser(id) {
  let token = userToken();
  let config = {
    method: 'DELETE',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
  };

  return callApi('user/' + id, config);
}

export async function addUpdateCronDuration(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('cron_duration/add', config);
}

export async function listCronDuration() {
  let token = userToken();
  let config = {
    method: 'GET',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
  };

  return callApi('cron_duration/list', config);
}

export async function listLog(data) {
  let token = userToken();
  let config = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return callApi('log/list', config);
}

/**
 * Private functions
 */
async function callApi(endpoint, config) {
  const url = `${apiUrl}${endpoint}`;

  console.log('Url: ', url);
  console.log('Config: ', config);

  return axios({
    ...config,
    url: url,
  })
    .then(async (response) => {
      console.log('-----API RESPONSE-----', response.data);
      if (response.data.msg === AUTHENTICATION_FAILED) {
        await store.dispatch(logOut(''));
        return {status: 'Failed', msg: response.data.msg};
      } else {
        return response.data;
      }
    })
    .catch(function (error) {
      console.log(
        'There has been a problem with your fetch operation: ' + error.message,
      );
      if (error?.message) {
        return {
          status: 'Failed',
          msg: error.message,
        };
      }
      return {
        status: 'Failed',
        msg: 'Api Error',
      };
    });
}
