import * as actionTypes from "./types";

//login
export const loginAdmin = data => ({
  type: actionTypes.LOGIN_ADMIN,
  payload: data,
});

export const loginAdminSuccess = data => ({
  type: actionTypes.LOGIN_ADMIN_SUCCESS,
  payload: data,
});

export const loginAdminFail = data => ({
  type: actionTypes.LOGIN_ADMIN_FAIL,
  payload: data,
});

//user
export const addUser = data => ({
  type: actionTypes.ADD_USER,
  payload: data,
});

export const addUserSuccess = data => ({
  type: actionTypes.ADD_USER_SUCCESS,
  payload: data,
});

export const addUserFail = data => ({
  type: actionTypes.ADD_USER_FAIL,
  payload: data,
});

export const listUser = data => ({
  type: actionTypes.LIST_USER,
  payload: data,
});

export const listUserSuccess = data => ({
  type: actionTypes.LIST_USER_SUCCESS,
  payload: data,
});

export const listUserFail = data => ({
  type: actionTypes.LIST_USER_FAIL,
  payload: data,
});

export const getUserDetail = data => ({
  type: actionTypes.GET_USER_DETAIL,
  payload: data,
});

export const getUserDetailSuccess = data => ({
  type: actionTypes.GET_USER_DETAIL_SUCCESS,
  payload: data,
});

export const getUserDetailFail = data => ({
  type: actionTypes.GET_USER_DETAIL_FAIL,
  payload: data,
});

export const updateUser = data => ({
  type: actionTypes.UPDATE_USER,
  payload: data,
});

export const updateUserSuccess = data => ({
  type: actionTypes.UPDATE_USER_SUCCESS,
  payload: data,
});

export const updateUserFail = data => ({
  type: actionTypes.UPDATE_USER_FAIL,
  payload: data,
});

export const updateUserData = data => ({
  type: actionTypes.UPDATE_USER_DATA,
  payload: data,
});

export const updateUserDataSuccess = data => ({
  type: actionTypes.UPDATE_USER_DATA_SUCCESS,
  payload: data,
});

export const updateUserDataFail = data => ({
  type: actionTypes.UPDATE_USER_DATA_FAIL,
  payload: data,
});

export const deleteUser = (id) => ({
  type: actionTypes.DELETE_USER,
  id: id,
});

export const deleteUserSuccess = data => ({
  type: actionTypes.DELETE_USER_SUCCESS,
  payload: data,
});

export const deleteUserFail = data => ({
  type: actionTypes.DELETE_USER_FAIL,
  payload: data,
});

//settings
export const addUpdateCronDuration = data => ({
  type: actionTypes.ADD_UPDATE_CRON_DURATION,
  payload: data,
});

export const addUpdateCronDurationSuccess = data => ({
  type: actionTypes.ADD_UPDATE_CRON_DURATION_SUCCESS,
  payload: data,
});

export const addUpdateCronDurationFail = data => ({
  type: actionTypes.ADD_UPDATE_CRON_DURATION_FAIL,
  payload: data,
});

export const listCronDuration = data => ({
  type: actionTypes.LIST_CRON_DURATION,
});

export const listCronDurationSuccess = data => ({
  type: actionTypes.LIST_CRON_DURATION_SUCCESS,
  payload: data,
});

export const listCronDurationFail = data => ({
  type: actionTypes.LIST_CRON_DURATION_FAIL,
  payload: data,
});

//logs
export const listLog = data => ({
  type: actionTypes.LIST_LOG,
  payload: data,
});

export const listLogSuccess = data => ({
  type: actionTypes.LIST_LOG_SUCCESS,
  payload: data,
});

export const listLogFail = data => ({
  type: actionTypes.LIST_LOG_FAIL,
  payload: data,
});

export const updateAlertMsg = data => ({
  type: actionTypes.UPDATE_ALERT_MSG,
  payload: data,
});

export const updateDrawerOpen = (data) => ({
  type: actionTypes.UPDATE_DRAWER_OPEN,
  payload: data,
});

export const updateApiData = data => ({
  type: actionTypes.UPDATE_API_DATA,
  payload: data,
});

export const updateApiStatus = data => ({
  type: actionTypes.UPDATE_API_STATUS,
  payload: data,
});

export const logOut = () => ({
  type: actionTypes.LOG_OUT,
});
