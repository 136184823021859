import React, {useEffect, useState} from 'react';
import {Grid, Box, Typography} from '@mui/material';
import {useNavigate} from "react-router-dom";

import FormTextInput from "../../components/FormTextInput";
import FormButton from "../../components/FormButton";
import AlertMsg from "../../components/AlertMsg";

import {connect} from "react-redux";
import {loginAdmin} from "../../redux/actions/userDataActions";

import EyeIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import EyeOffIcon from '@mui/icons-material/VisibilityOffOutlined';

import AuthRightBottomImg from '../../assets/img/auth_right_bottom.png';
import AuthTopLeftImg from '../../assets/img/auth_top_left.png';

import styles from "./Login.module.css"

const Login = (props) => {
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [passwordEyeVisible, setPasswordEyeVisible] = useState(false);
  const [error, setError] = useState(null);
  const [enterKeyPressed, setEnterKeyPressed] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (enterKeyPressed) {
      onSignIn();
    }
  }, [enterKeyPressed])

  useEffect(() => {
    const listener = event => {
      setEnterKeyPressed(event.code === "Enter");
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, []);

  const onSignIn = () => {
    let errorText = {};
    if (userName == '') {
      errorText = {userName: 'Please enter username'};
    }
    if (password == '') {
      errorText = {...errorText, password: 'Please enter password'};
    }

    if (Object.keys(errorText).length != 0) {
      setError(errorText);
      return;
    }

    let data = {
      username: userName,
      password: password
    };
    props.loginAdmin(JSON.stringify(data))
  };

  const onPasswordEyeIconClick = () => {
    if (passwordEyeVisible) {
      setPasswordEyeVisible(false)
    } else {
      setPasswordEyeVisible(true)
    }
  };

  const onChange = (val, type) => {
    let errorText = error;
    if (type == 'username') {
      errorText = {...error, userName: ''};
      setUserName(val)
    } else if (type == 'password') {
      errorText = {...error, password: ''};
      setPassword(val)
    }
    setError(errorText);
  };

  return (
    <Grid container item md={12} xs={12} display={"flex"} flexDirection={"row"}
          alignItems={"center"} justifyContent={"center"} className={styles.cardMainView}>


      <Grid item md={2} xs={12}>
        <Box className={styles.boxLeftView}>
          <img alt="" src={AuthTopLeftImg} className={styles.imgStyle}/>
        </Box>
      </Grid>


      <Grid item md={8} xs={12} display={"flex"} alignItems={"center"} justifyContent={"center"}>
        <Grid className={styles.cardView} sx={{
          minHeight: window.innerHeight - 350,
          width: {md: '40%', sm: '85%', xs: '85%'}
        }}>
          <Typography className={styles.subTitleText} mt={1}>Welcome to Us Visa Bot! 👋</Typography>
          <Typography className={styles.contentText} mt={1}>Please sign-in to your account and start the
            adventure</Typography>

          <Grid container item md={12} xs={12} mt={4}>
            <FormTextInput
              page={'Login'}
              error={error?.userName}
              value={userName}
              setValue={(val) => onChange(val, 'username')}
              label={'USERNAME'}
              placeholder={'Enter your username'}
            />
          </Grid>

          <Grid container item md={12} xs={12} mt={2.5}>
            <FormTextInput
              page={'Login'}
              error={error?.password}
              value={password}
              setValue={(val) => onChange(val, 'password')}
              label={'PASSWORD'}
              placeholder={'..........'}
              type={passwordEyeVisible ? 'text' : 'password'}
              icon={true}
              rightIcon={passwordEyeVisible ? <EyeIcon sx={style.eyeIconStyle}/> :
                <EyeOffIcon sx={style.eyeIconStyle}/>}
              onIconClick={onPasswordEyeIconClick}
            />
          </Grid>

          <Grid container
                item md={12} xs={12}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                mt={4}>
            <FormButton
              loading={props.loginBtnLoading}
              title={'Sign in'}
              btnStyleView={style.btnStyleView}
              onSubmit={onSignIn}
            />
          </Grid>

        </Grid>
      </Grid>

      <Grid item md={2} xs={12}>
        <Box className={styles.boxRightView}>
          <img alt="" src={AuthRightBottomImg} className={styles.imgStyle}/>
        </Box>
      </Grid>


      <AlertMsg/>

    </Grid>
  )
};

const mapStateToProps = (state) => {
  return {
    loginBtnLoading: state.userData.loginBtnLoading,
  };
};


const mapDispatchToProps = dispatch => {
  return {
    loginAdmin: data => dispatch(loginAdmin(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);

const style = {
  btnStyleView: {
    height: 40,
    width: '100%'
  },
  eyeIconStyle: {
    color: '#888',
    height: 15,
    width: 15
  }
}
