import React, {useEffect, useState} from "react";
import {
  Grid,
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
} from "@mui/material";
import {useNavigate} from "react-router-dom";
import moment from "moment";

import Container from "../../components/Container";
import Page from "../../components/Page";
import FormButton from "../../components/FormButton";
import ToggleSwitch from "../../components/ToggleSwitch";
import AlertMsg from "../../components/AlertMsg";
import {MOBILE_VIEW} from "../../components/Sidebar";

import {UTC_OFFSET} from '../../constants'

import {connect} from "react-redux";
import {
  listUser,
  updateUserData
} from "../../redux/actions/userDataActions";

import styles from "./Users.module.css";

import PlusIcon from '@mui/icons-material/AddOutlined';
import EditIcon from "@mui/icons-material/EditOutlined";
import TickIcon from '@mui/icons-material/Done';
import CrossIcon from '@mui/icons-material/Close';

const Users = (props) => {
  const [pageNum, setPageNum] = useState(1);
  const [totalListCount, setTotalListCount] = useState("");
  const [listCount, setListCount] = useState("");
  const [pageLimit, setPageLimit] = useState(50);

  const navigate = useNavigate();

  useEffect(() => {
    setTotalListCount(props.userTotalList);
  }, [props.userTotalList]);

  useEffect(() => {
    setListCount(props.userList?.length);
  }, [props.userList?.length]);


  useEffect(() => {
    getUsersList(1);
  }, []);

  const getUsersList = (page) => {
    let data = {
      page: page,
    };
    props.listUser(JSON.stringify(data));
    setPageNum(page);
  };

  const onAddUserClick = () => {
    navigate('/add_edit_user')
  };

  const onEditUser = (e, item) => {
    e.stopPropagation();
    navigate("/add_edit_user/" + item?.uid);
  };

  const onSelectPage = (page) => {
    getUsersList(page);
  };

  const onToggle = (item, type) => {

    let updateData = {}
    if(type === 'slot_check') {
      if(item?.slot_check) {
        updateData = {
          slot_check : 0,
          auto_booking : 0,
        }
      } else {
        updateData = {
          slot_check : 1
        }
      }
    } else if(type === 'auto_booking') {
      updateData = {
        auto_booking: item?.auto_booking ? 0 : 1
      }
    }

    let data = {
      userUid: item?.uid,
      data: updateData
    };
    props.updateUserData(JSON.stringify(data));
  };


  return (
    <Container page={"users"} title={"Users"}>
      <Grid container item md={12} xs={12}>
        <Grid
          item
          md={12}
          xs={12}
          container
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          className={styles.cardView}
        >
          <TableContainer className={styles.tableView}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell colSpan={10}>
                    <Grid
                      display={"flex"}
                      flexDirection={"row"}
                      justifyContent={"flex-end"}
                      mt={2}
                    >
                      <Grid item md={2} xs={2} ml={2}>
                        <FormButton
                          title={
                            MOBILE_VIEW ? (
                              <PlusIcon/>
                            ) : (
                              "Add New User"
                            )
                          }
                          startIcon={
                            MOBILE_VIEW ? null : (
                              <PlusIcon/>
                            )
                          }
                          btnStyleView={style.btnStyleView}
                          onSubmit={(e) =>
                            onAddUserClick()
                          }
                        />
                      </Grid>
                    </Grid>
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableHead>
                <TableRow>
                  <TableCell>Email</TableCell>
                  <TableCell>Password</TableCell>
                  <TableCell>Schedule ID</TableCell>
                  <TableCell>Booked Date</TableCell>
                  <TableCell>Time Period (IN MONTHS)</TableCell>
                  <TableCell>Telegram Registered</TableCell>
                  <TableCell>Timestamp</TableCell>
                  <TableCell>Slot Check</TableCell>
                  <TableCell>Auto Booking</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {props.userLoading ? (
                  <TableRow>
                    <TableCell colSpan={10} align="center">
                      <CircularProgress/>
                    </TableCell>
                  </TableRow>
                ) : props.userList?.length > 0 ? (
                  props.userList?.map((item, index) => (
                    <TableRow
                      key={index}
                    >
                      <TableCell>{item?.email}</TableCell>
                      <TableCell>{item?.password}</TableCell>
                      <TableCell>{item?.schedule_id ? item?.schedule_id : '-'}</TableCell>
                      <TableCell>{item?.booked_date ? moment(item.booked_date).format("DD-MM-YYYY") : '-'}</TableCell>
                      <TableCell>{item?.time_period ? item?.time_period : '-'}</TableCell>
                      <TableCell>
                        {item?.chat_id ? <TickIcon fontSize="small" color={'success'}/> :
                          <CrossIcon fontSize="small" style={{color: 'red'}}/>}
                      </TableCell>
                      <TableCell>{moment.utc(item?.timestamp).utcOffset(UTC_OFFSET).format("DD-MM-Y hh:mm A")}</TableCell>
                      <TableCell>
                        <ToggleSwitch
                          data={item}
                          checkedVal={item?.slot_check ?? 0}
                          onToggle={() => onToggle(item, 'slot_check')}/>
                      </TableCell>
                      <TableCell>
                        <ToggleSwitch
                          disabled={!item?.slot_check}
                          hover={!item?.slot_check}
                          hoverText={'Turn ON slot check to manage auto booking'}
                          data={item}
                          checkedVal={item?.auto_booking ?? 0}
                          onToggle={() => onToggle(item, 'auto_booking')}/>
                      </TableCell>
                      <TableCell>
                        <Box
                          onClick={(e) =>
                            onEditUser(e, item)
                          }
                          className={styles.iconView}
                        >
                          <EditIcon fontSize="small"/>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={10} align="center">
                      <Typography className={styles.noMatchFoundText}>
                        No Users found
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          <Grid
            item
            md={12}
            xs={12}
            display={"flex"}
            flexDirection={{md: "row", xs: "column"}}
            alignItems={"center"}
          >
            <Grid
              item
              md={6}
              xs={12}
              display={"flex"}
              justifyContent={{md: "flex-start", xs: "unset"}}
              mt={2}
              mb={{md: 2, xs: 0}}
              pl={{md: 2, xs: 0}}
            >
              <Typography className={styles.showingEntriesText}>
                Showing{" "}
                {listCount == 0
                  ? 0
                  : pageNum == 1
                    ? 1
                    : parseInt((pageNum - 1) * pageLimit) + 1}{" "}
                to{" "}
                {listCount == 0
                  ? 0
                  : parseInt((pageNum - 1) * pageLimit) + listCount}{" "}
                of {totalListCount} entries
              </Typography>
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
              display={"flex"}
              justifyContent={{md: "flex-end", xs: "unset"}}
              mt={2}
              mb={2}
              pr={{md: 2, xs: 0}}
            >
              <Page
                totalPages={props.userTotalPages}
                pageNum={pageNum}
                onSelectPage={onSelectPage}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <AlertMsg/>
    </Container>
  )
};

const mapStateToProps = (state) => {
  return {
    userTotalPages: state.userData.userTotalPages,
    userTotalList: state.userData.userTotalList,
    userList: state.userData.userList,
    userLoading: state.userData.userLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    listUser: (data) => dispatch(listUser(data)),
    updateUserData: (data) => dispatch(updateUserData(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Users);

const style = {}
