import React, {useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import IconButton from "@mui/material/IconButton";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";

import { connect } from "react-redux";
import { updateDrawerOpen } from "../redux/actions/userDataActions";

import MenuToggleOnIcon from '@mui/icons-material/RadioButtonCheckedOutlined';
import MenuToggleOffIcon from '@mui/icons-material/RadioButtonUncheckedOutlined';
import CloseIcon from '@mui/icons-material/CloseOutlined';
import UserIcon from '@mui/icons-material/PersonOutlineOutlined';
import SettingsIcon from '@mui/icons-material/Settings';
import LogsIcon from '@mui/icons-material/FormatListBulleted';

import { THEME_COLOR, THEME_COLOR2, THEME_COLOR3 } from "../constants";

import styles from "./Sidebar.module.css";

export const MOBILE_VIEW = window.innerWidth < 600;
export const DRAWER_WIDTH = 240;
export const SMALL_DRAWER_WIDTH = MOBILE_VIEW ? 0 : 70;
const ICON_SiZE = 20;

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const openedMixin = (theme) => ({
  backgroundColor: THEME_COLOR2,
  width: DRAWER_WIDTH,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
  boxShadow: MOBILE_VIEW
    ? "0 0.625rem 1.25rem rgba(147, 158, 170, 0.5)"
    : "unset",
  ...(MOBILE_VIEW
    ? { position: "fixed", top: 0, bottom: 0, zIndex: 1201 }
    : null),
});

const closedMixin = (theme, hoverOpen) => ({
  backgroundColor: THEME_COLOR2,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: hoverOpen ? DRAWER_WIDTH : SMALL_DRAWER_WIDTH,
  boxShadow:
    hoverOpen || MOBILE_VIEW
      ? "0 0.625rem 1.25rem rgba(147, 158, 170, 0.5)"
      : "unset",
  ...(hoverOpen || MOBILE_VIEW
    ? {
        position: "fixed",
        top: 0,
        bottom: 0,
        zIndex: 1201,
      }
    : null),
});

const SlideDrawer = styled(MuiDrawer)(({ theme, open, drawerHoverOpen }) => ({
  width: DRAWER_WIDTH,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open &&
    (drawerHoverOpen || !drawerHoverOpen || MOBILE_VIEW) && {
      ...closedMixin(theme, drawerHoverOpen),
      "& .MuiDrawer-paper": closedMixin(theme, drawerHoverOpen),
    }),
}));

const Sidebar = (props) => {
  const navigate = useNavigate();

  let MENU_LIST = [
    {
      page: "users",
      label: "Users",
      icon: (
        <Box>
          <UserIcon/>
        </Box>
      ),
      activeIcon: (
        <Box sx={{path: {fill: THEME_COLOR}}}>
          <UserIcon/>
        </Box>
      ),
      href: "/",
    },
    {
      page: "settings",
      label: "Settings",
      icon: (
        <Box>
          <SettingsIcon fontSize={'small'}/>
        </Box>
      ),
      activeIcon: (
        <Box sx={{path: {fill: THEME_COLOR}}}>
          <SettingsIcon fontSize={'small'}/>
        </Box>
      ),
      href: "/settings",
    },
    {
      page: "logs",
      label: "Logs",
      icon: (
        <Box>
          <LogsIcon fontSize={'small'}/>
        </Box>
      ),
      activeIcon: (
        <Box sx={{path: {fill: THEME_COLOR}}}>
          <LogsIcon fontSize={'small'}/>
        </Box>
      ),
      href: "/logs",
    }
    ];

  useEffect(() => {
    props.setDrawerHoverOpen(false);
  }, [])

  const handleDrawerToggle = () => {
    if (props.drawerHoverOpen) {
    } else {
      props.updateDrawerOpen(!props.drawerOpen);
    }
  };

  const handleToggleBtnClick = () => {
    if (props.drawerHoverOpen && !MOBILE_VIEW) {
      props.setDrawerHoverOpen(false);
    }
    props.updateDrawerOpen(!props.drawerOpen);
  };

  const DrawerView = () => {
    return (
      <>
        <DrawerHeader
          sx={{ justifyContent: "space-between", alignItems: "center", mb: 1 }}
        >
          <Box px={2} sx={{ display: "flex", alignItems: "center" }}>
          </Box>
          <IconButton onClick={handleToggleBtnClick}>
            {MOBILE_VIEW ? (
              <CloseIcon sx={{color:'#5a8dee'}}/>
            ) : props.drawerOpen ? (
              <MenuToggleOnIcon sx={{color:'#5a8dee'}} />
            ) : props.drawerHoverOpen ? (
              <MenuToggleOffIcon sx={{color:'#5a8dee'}} />
            ) : null}
          </IconButton>
        </DrawerHeader>
        <List>
          {MENU_LIST?.map((menu, index) => (
            <>
            <ListItem
              key={index}
              onClick={() => menu?.href ? navigate(menu?.href) : null}
              disablePadding
              sx={{
                display: "block",
                color: THEME_COLOR3,
                mb: 0.4,
              }}
            >
              <ListItemButton
                className={styles.listItemBtn}
                sx={{
                  justifyContent:
                    props.drawerOpen || props.drawerHoverOpen
                      ? "initial"
                      : "center",
                  backgroundColor:
                    props.page == menu?.page ? "#dbe4f3" : "transparent",
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    justifyContent: "center",
                    "& .MuiBox-root": {
                      lineHeight: "0 !important",
                    },
                    mr: props.drawerOpen || props.drawerHoverOpen ? 1.2 : 0,
                  }}
                >
                  {props.page == menu?.page ? menu?.activeIcon : menu?.icon}
                </ListItemIcon>
                {props.drawerOpen || props.drawerHoverOpen ? (
                  <ListItemText
                    primary={menu?.label}
                    sx={{
                      opacity:
                        props.drawerOpen || props.drawerHoverOpen ? 1 : 0,
                      color:
                        props.page == menu?.page ? THEME_COLOR : THEME_COLOR3,
                      "& .MuiTypography-root": {
                        fontFamily:
                          props.page == menu?.page
                            ? "IBMPlexSansMedium"
                            : "IBMPlexSansRegular",
                      },
                    }}
                  />
                ) : null}
              </ListItemButton>
            </ListItem>
            </>
          ))}
        </List>
      </>
    );
  };

  return (
    <Box
      sx={
        !MOBILE_VIEW
          ? { position: props.drawerOpen ? "absolute" : "fixed", zIndex: 1203 }
          : null
      }
      component="nav"
      onMouseOver={() =>
        MOBILE_VIEW
          ? null
          : !props.drawerOpen &&
            document.getElementById("sidebarDrawer")?.offsetWidth ==
              SMALL_DRAWER_WIDTH
          ? props.setDrawerHoverOpen(true)
          : null
      }
      onMouseLeave={() =>
        MOBILE_VIEW
          ? null
          : !props.drawerOpen && props.drawerHoverOpen
          ? props.setDrawerHoverOpen(false)
          : null
      }
    >
      <SlideDrawer
        id={"sidebarDrawer"}
        variant={"permanent"}
        open={props.drawerOpen}
        onClose={handleDrawerToggle}
        drawerHoverOpen={props.drawerHoverOpen}
      >
        <DrawerView />
      </SlideDrawer>
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    userType: state.userData.userType,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateDrawerOpen: (data) => dispatch(updateDrawerOpen(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
