import {configureStore} from '@reduxjs/toolkit';
import {persistStore} from 'redux-persist';
import logger from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import rootSaga from '../saga';
import rootReducer from '../reducers';

const sagaMiddleware = createSagaMiddleware();
const store = configureStore({
  reducer: rootReducer,
  middleware: () => [sagaMiddleware, logger]
});

const persistor = persistStore(store);
sagaMiddleware.run(rootSaga);

export { store, persistor };
