import React from 'react';
import Button from '@mui/material/Button';

import styles from "./FormButton.module.css"

const FormButton = (props) => {

  let btnStyle = styles.buttonView;
  let btnHoverStyle = styles.buttonHoverView;

  if(props?.btnType == 'delete') {
    btnStyle = styles.deleteButtonView;
    btnHoverStyle = styles.deleteButtonHoverView;
  }

  return (
    <Button
      startIcon={props?.startIcon}
      disableElevation={true}
      disabled={props?.btnDisabled ? props?.btnDisabled : props?.loading}
      className={[btnStyle, styles.btnView, btnHoverStyle]}
      sx={props?.btnStyleView}
      onClick={props.onSubmit}
      variant="contained"
      size="medium">
      {props?.loading ? 'Please Wait' : props.title}
    </Button>
  )
};

export default FormButton;
