export const ENV = 'PROD';
export const AUTHENTICATION_FAILED = 'Authentication Failed';

export const THEME_COLOR = '#5a8dee';
export const THEME_COLOR2 = '#f3f4f4';
export const THEME_COLOR3 = '#677788';

export const FACILITY_ID = '92,94,95';
export const LOCALE = 'en-ca';

export const UTC_OFFSET = "+05:30";

export const NAVBAR_HEIGHT = 64;
export const FOOTER_HEIGHT = 40;


